<template>
  <div class="ss-select-org-modal">
    <a-modal title="选择组织机构"
             :visible="modalShown"
             :confirm-loading="confirmLoading"
             :mask-closable="false"
             :width="800"
             :centered="true"
             :destroy-on-close="true"
             wrap-class-name="select-org-modal"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-input-search v-model="filterTxt" :allow-clear="true" placeholder="请输入组织机构名称" enter-button="搜 索"
                      @search="onSearch"/>

      <a-spin v-if="!options || !options.length" size="large" tip="加载中..." class="ss-spin"></a-spin>

      <template v-if="filteredShown">
        <div v-if="!filteredOptions || !filteredOptions.length" class="empty mt-20">
          <span class="empty-text">未查询到组织机构</span>
        </div>
        <a-checkbox-group v-model="filterCheckedKeys" class="mt-20">
          <template v-for="item of filteredOptions">
            <a-checkbox :key="item.id" :value="item.id">{{ item.name }}</a-checkbox>
          </template>
        </a-checkbox-group>
      </template>

      <a-tree v-else
              v-model="checkedKeys"
              :checkable="true"
              :check-strictly="true"
              :replace-fields="fieldMap"
              :auto-expand-parent="autoExpandParent"
              :default-expand-all="false"
              :expanded-keys="expandedKeys"
              :tree-data="options"
              @expand="onExpand"
              @check="onCheck"/>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/utils/Common';

export default {
  name: 'SelectOrgModal',
  props: {
    defaultSelectedIds: { type: String, default: '' },
    mode: { type: String, default: 'multiple', validator: (val) => ['single', 'multiple'].includes(val) },
    onlyLeaf: { type: Boolean, default: false }, // 只允许选择没有子节点的数据
  },
  computed: {
    ...mapState({
      options: state => state.Common.orgTree,
    }),
  },
  watch: {
    filterTxt(text) {
      if (!text) {
        this.cancelSearch();
      }
    },
    defaultSelectedIds() {
      this.setDefault();
    },
  },
  data() {
    return {
      modalShown: true,
      confirmLoading: false,
      loading: true,

      filteredShown: false,
      filterTxt: '',
      filteredOptions: [],
      filterCheckedKeys: [],

      autoExpandParent: true,
      expandedKeys: [],
      checkedKeys: { checked: [], halfChecked: [] },
      fieldMap: { children: 'children', title: 'name', key: 'id' },
    };
  },
  created() {
    this.setDefault();
  },
  methods: {
    onSearch() {
      if (!this.filterTxt) {
        return this.cancelSearch();
      }

      const options = [...this.options];
      const filteredOptions = [];

      for (let i = 0; i < options.length; i++) {
        if (!options[i]?.id) {
          continue;
        }

        if (options[i].children && options[i].children.length) {
          options.push(...options[i].children);
        }

        if (options[i].name.indexOf(this.filterTxt) > -1) {
          filteredOptions.push({
            id: options[i].id,
            name: options[i].name,
          });
        }
      }

      this.filteredShown = true;
      this.filterCheckedKeys = this.checkedKeys.checked || [];
      this.filteredOptions = filteredOptions;
    },
    cancelSearch() {
      const keys = this.checkedKeys;
      const checked = keys.checked || [];

      keys.checked = checked.concat(this.filterCheckedKeys.filter(i => !checked.includes(i)));

      this.checkedKeys = keys;

      this.filteredShown = false;
      this.filteredOptions = [];
    },
    getSelectedItems(checkedKeys, options) {
      return Common.getSelectedItems(checkedKeys, options, { id: 'id', name: 'name' });
    },
    getCheckedKeysArr() {
      if (!this.checkedKeys) {
        return [];
      }
      if (this.checkedKeys.checked) {
        return this.checkedKeys.checked;
      }
      return this.checkedKeys || [];
    },
    confirmModal() {
      this.cancelSearch();
      const data = this.getSelectedItems(this.getCheckedKeysArr(), this.options);
      this.$emit('confirm', { data });
    },
    closeModal() {
      this.$emit('close');
      this.updateTreeEnabled([]);
    },
    setDefault() {
      // if (!this.defaultSelectedIds) {
      //   return;
      // }
      const ids = this.defaultSelectedIds.split(',').filter((i, idx) => i && !idx || this.mode === 'multiple');
      this.checkedKeys = { checked: ids.map(i => +i), halfChecked: [] };
      this.updateTreeEnabled(this.checkedKeys?.checked || []);

      if (this.onlyLeaf && !ids?.length) {
        this.expandedKeys = [...Common.getFirstLeafPath(this.options).map((i) => +i.id)];
      } else {
        this.expandedKeys = [...this.checkedKeys?.halfChecked];
      }
    },

    updateTreeEnabled(checkedKeys) {
      const options = [...this.options];

      if (options && options.length) {
        options.forEach(item => this.updateItemEnabled(item, true, checkedKeys));
      }
      this.checkedKeys = {
        checked: [...this.checkedKeys?.checked || []],
        halfChecked: [...this.checkedKeys?.halfChecked || []],
      };
      this.$store.commit('Common/updateOrgTree', options);
    },

    updateItemEnabled(item, enable, checkedKeys) {
      item.disabled = !enable;

      if (item.disabled) {
        this.remove(this.checkedKeys?.checked, item.id);
      }

      if (enable && checkedKeys.indexOf(item.id) > -1) {
        enable = false;
      }

      let childrenSelectionCount = item.children?.length || 0;
      if (item.children && item.children.length) {
        if (this.onlyLeaf) {
          item.disabled = true;
        }

        item.children.forEach(i => {
          const res = this.updateItemEnabled(i, enable, checkedKeys);
          res.checked && childrenSelectionCount--;
          res.halfChecked && (childrenSelectionCount -= 0.5);
        });

        if (childrenSelectionCount === item.children.length) {
          this.remove(this.checkedKeys?.halfChecked, item.id);
        } else if (childrenSelectionCount > 0) {
          this.add(this.checkedKeys?.halfChecked, item.id);
        } else {
          item.children.forEach(i => {
            this.remove(this.checkedKeys?.checked, i.id);
            i.disabled = true;
          });
          this.add(this.checkedKeys?.checked, item.id);
        }
      }

      return {
        id: item.id,
        name: item.name,
        disabled: item.disabled,
        checked: this.checkedKeys?.checked?.includes(item.id),
        halfChecked: this.checkedKeys?.halfChecked?.includes(item.id),
      };
    },

    remove(arr, item) {
      if (!arr || !arr.length) {return;}
      const idx = arr.indexOf(item);
      if (idx > -1) {
        arr.splice(idx, 1);
      }
    },

    add(arr, item) {
      if (!arr) {return;}
      this.remove(arr, item);
      arr.push(item);
    },

    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.autoExpandParent = false;
      this.expandedKeys = expandedKeys;
    },
    onCheck(checkedKeys) {
      if (this.mode === 'single') {
        if (checkedKeys.checked?.length > 1) {
          checkedKeys.checked.shift();
        }
      }
      this.updateTreeEnabled(checkedKeys?.checked || []);
    },
    onselect(selectedKeys) {
      if (!selectedKeys || !selectedKeys.length) {
        return;
      }
      const expandedKeys = this.expandedKeys;
      selectedKeys.forEach(i => {
        const idx = expandedKeys.findIndex(e => e === i);
        if (idx > -1) {
          expandedKeys.splice(idx, 1);
        } else {
          expandedKeys.push(i);
        }
        this.expandedKeys = expandedKeys;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ss-select-org-modal {
}
</style>

<style lang="scss">
.select-org-modal {
  .ss-spin {
    display: block;
    margin: 35px auto;
  }

  .empty {
    text-align: center;
  }

  .empty-text {
    font-size: 14px;
    color: #999999;
  }

  .ant-modal-body {
    overflow-y: auto;
    max-height: 70vh;
    min-height: 280px;
  }
}
</style>
